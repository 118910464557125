import { Todo } from '@lib/common/type';
import moment from 'moment';
import {
  ActualStocks,
  AmountDetail,
  DeliveryAmount,
  EstimatedAmount,
  MaterialInventoryData,
} from '@lib/pc/materials_inventory/type';

export const isSameDayWithOutTime = (d1: Date, d2: Date) => {
  return moment(d1).isSame(d2, 'day');
};

// numに15を指定することで前後7日間を表示
export const generateDateArray = (num: number, date = moment()) => {
  return [...Array(num)].map((_, i) => {
    const today = new Date();
    const d = moment(date).toDate();
    d.setDate(d.getDate() + (i - Math.floor(num / 2))); // dateを基準に前後 num/2 日間
    return {
      productionDate: d,
      isToday: isSameDayWithOutTime(d, today),
    };
  });
};

// 実在庫数 (青)
export const convertActualOrSafetyStockBarData = (data: ActualStocks[]) => {
  const newPackagedData = data.map((p: ActualStocks, i: number) => {
    const y = Math.max(p.amount ? Number(p.amount) : 0, 0);

    return {
      x: i,
      y: isFinite(y) ? y : 0,
    };
  });

  return newPackagedData;
};

// 予定在庫数 (赤)
export const convertStockEstimatedArrToBarData = (data: EstimatedAmount[]) => {
  const newPackagedData = data.map((a: EstimatedAmount, i: number) => {
    return {
      id: a.estimatedMaterialStocksId,
      x: i,
      y: a.amount,
    };
  });

  return newPackagedData;
};

// 納品済数
export const convertSafetyStocksArrToBarData = (data: DeliveryAmount[]) => {
  const newPackagedData = data.map((a: DeliveryAmount, i: number) => {
    const y = Math.max(a.amount ? a.amount : 1, 0);

    return {
      x: i,
      y: isFinite(y) ? y : 0,
    };
  });

  return newPackagedData;
};

export const findMinAmount = (data: MaterialInventoryData) => {
  const allAmounts: number[] = [
    ...data.actualDeliveries.map((item) => item.amount),
    ...data.actualStocks.map((item) => item.amount),
    ...data.estimatedDeliveries.map((item) => item.amount),
    ...data.estimatedStocks.map((item) => item.amount),
  ];

  const min = Math.min(...allAmounts);

  // dataが[]場合nullのmin「Infinity」になりますので、ガードする
  if (isFinite(min)) return min;
  else return 0;
};

// グラフ左のY軸の最大値を求める
export const findMaxAmount = (data: MaterialInventoryData) => {
  const allAmounts: number[] = [
    ...data.actualDeliveries.map((item) => item.amount),
    ...data.actualStocks.map((item) => item.amount),
    ...data.estimatedDeliveries.map((item) => item.amount),
    ...data.estimatedStocks.map((item) => item.amount),
  ];

  const max = Math.max(...allAmounts);

  // dataが[]場合nullのmax「Infinity」になりますので、ガードする
  if (isFinite(max)) return max;
  else return 0;
};

// 【在庫数の予定と実績】グラフ左のY軸の値
export const convertActualOrSafetyStocksArrToBarData = (
  arr: Todo[],
  maxYCoordinate: number
) => {
  return arr.map((a: Todo, i: number) => {
    return {
      id: a.id,
      x: i,
      y: maxYCoordinate,
    };
  });
};

// ケース・バラまとめ単位・バラの共通の計算式
export const calcTotalFunc = (
  newDetail: AmountDetail,
  piecesPerBox: number,
  piecesPerUnit: number
) => {
  // 数値変換を行い、無効な値があれば0を使用する
  const pieceValue = newDetail.piece
    ? parseFloat(newDetail.piece.toString())
    : 0;

  if (pieceValue || newDetail.unit || newDetail.case) {
    newDetail.total =
      pieceValue +
      Number(newDetail.unit || 0) * (piecesPerUnit || 1) +
      Number(newDetail.case || 0) * (piecesPerBox || 1) * (piecesPerUnit || 1);
  } else {
    newDetail.total = 0;
  }
  return newDetail.total;
};

//  配列内で値が重複してないか調べる
const existsSameValue = (a: Todo) => {
  const s = new Set(a);
  return s.size != a.length;
};

// 日付のみまたは日付とケースの重複チェック
export const duplicateDateCheck = (inputParamsAmountDetail: Todo) => {
  const checkDates: Todo = [];
  inputParamsAmountDetail.map((p: Todo) => {
    const checkDate =
      // eslint-disable-next-line no-prototype-builtins
      !p.hasOwnProperty('expirationDate') || p?.expirationDate === null
        ? ''
        : p?.expirationDate;
    checkDates.push(checkDate);
  });
  const result = existsSameValue(checkDates);
  return result;
};

// 在庫数必須入力チェック
export const totalCheck = (inputParamsAmountDetail: Todo) => {
  if (inputParamsAmountDetail.some((ip: Todo) => ip.total === 0)) {
    return true;
  }
};
