import styled, { css } from 'styled-components';

export const ListWrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 165px);

  overflow: scroll;
  display: flex;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

const highLightenHeadCell = css`
  height: 49px;
  color: ${(props) => props.theme.palette.white}!important;
  background: ${(props) => props.theme.palette.blue}!important;
  border-color: ${(props) => props.theme.palette.blue}!important;
`;

// 月日、合計、前年同月
export const ListHeadCell = styled.div<{
  highLighten: boolean;
  dayOfWeek?: string;
  index: number;
  numOfIndex: number;
}>`
  height: 49px;
  width: 91px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: sticky;

  /* 前年同月 */
  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
  }

  /* 合計 */
  :nth-last-child(2) {
    position: sticky;
    top: 0;
    right: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  ${({ highLighten }) => (highLighten ? highLightenHeadCell : null)}

  color: ${(props) =>
    props.dayOfWeek === '土' && props.index <= props.numOfIndex
      ? '#1046cf'
      : props.dayOfWeek === '日' && props.index <= props.numOfIndex
      ? '#c74141'
      : ''};
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;
  -webkit-transition: transform 320ms ease-in-out;
  -moz-transition: transform 320ms ease-in-out;
  -ms-transition: transform 320ms ease-in-out;
  -o-transition: transform 320ms ease-in-out;
  transition: transform 320ms ease-in-out;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    z-index: 4;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }

  &.move-to-left {
    -webkit-transform: translateX(-320px);
    -moz-transform: translateX(-320px);
    -ms-transform: translateX(-320px);
    -o-transform: translateX(-320px);
    transform: translateX(-320px);
  }

  & > div:nth-child(2) {
    /* margin-top: 3px;  この設定でinfiniteScrollが効かなくなる*/
  }

  & > div:not(:first-child):nth-child(even) > input:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }
  & > div:not(:first-child):nth-child(odd) > input:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
  }

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  & > div:not(:first-child):nth-child(even) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }
  & > div:not(:first-child):nth-child(odd) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
  }
`;

// 月日、合計、前年同月ラッパー
export const ListRow = styled.div<{
  notDisplay?: string;
}>`
  display: ${(props) => (props.notDisplay === 'true' ? 'none' : 'flex')};
  background: #fff;
  position: -webkit-sticky; /* for Safari */
  position: sticky;

  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    z-index: 4;
    height: 49px;
    /* height: 52px; infiniteScrollが効かなくなる */
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const ListSidebar = styled.div`
  min-width: 300px;
  height: max-content;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};

  position: sticky;
  left: -1px;
  z-index: 5;

  & > div:first-child {
    background: ${(props) => props.theme.palette.paleYellow};

    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    padding: 1px;
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1000;
  }
`;

export const SearchArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.25rem;
`;

// 商品の絞込、表示選択
export const ListLeftHeadCell = styled.div<{
  isBlue?: boolean;
}>`
  height: 49px;
  min-width: 298px;
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  position: -webkit-sticky; /* for Safari */
  display: flex;
  justify-content: space-between;
  padding: 6px 0.5rem;
  gap: 0.25rem;
  flex-direction: column;

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 61%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        padding-left: 3px;
        font-size: 0.7rem;
      }
    }
  }

  :first-child {
    background-color: #f5f2e6 !important;
    > div > input {
      cursor: pointer;
      height: 32px;
    }
  }
`;

export const DisplayButton = styled.div<{
  isBlue: boolean;
}>`
  > span {
    &.filter-product {
      width: 29%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      top: 7px;
      right: 8px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        font-size: 0.7rem;
      }
    }
  }

  > svg {
    position: absolute;
    top: 12%;
    left: 5%;
  }
`;

// 商品名、商品コードラッパー
export const ListLeftHeadProductCell = styled.div<{
  invalid?: boolean;
  productIndex: number;
}>`
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  padding-left: 0.75rem;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.theme.palette.white
      : props.theme.palette.paleYellow};
`;

export const ListTbody = styled.div`
  display: flex;
`;

// 商品名、商品コード
export const ListLeftHeadNameAndCode = styled.div<{
  productIndex: number;
}>`
  width: 205px;
  vertical-align: middle;
  border-right: 1px solid #efefef;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.theme.palette.white
      : props.theme.palette.paleYellow};
`;

export const ListProductNameAndCode = styled.div`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ListProductName = styled.div`
  color: ${(props) => props.theme.palette.blue};
`;

// 表示タイプ
export const ListLeftHeadTypeName = styled.div<{
  notDisplay: string;
  productIndex: number;
  typeIndex: number;
}>`
  width: 100px;
  height: 30px;
  font-size: 15px;
  color: #6b675c;
  text-align: left;
  padding-left: 1.1rem;
  vertical-align: middle;
  display: ${(props) => (props.notDisplay === 'true' ? 'none' : 'flex')};
  border-bottom: 1px solid #efefef;
  align-items: center;
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.typeIndex % 2 === 0
        ? props.theme.palette.white
        : props.theme.palette.paleYellow
      : props.typeIndex % 2 === 0
      ? props.theme.palette.paleYellow
      : props.theme.palette.white};
`;

export const CircularIconWrapper = styled.div<{
  infiniteScroll?: boolean;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background-color: ${(props) =>
    !props.infiniteScroll ? props.theme.palette.beige : ''} !important;
`;

export const InfiniteScrollWrapper = styled.div`
  width: 100%;
`;

export const ObserverTarget = styled.div<{
  tagMenuActive: boolean;
}>`
  width: 100%;
  display: ${(props) => (props.tagMenuActive ? 'none' : 'block')};
`;
