import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import humps from 'humps';
import { CircularProgress } from '@material-ui/core';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import DisplaySelectModal from '@components/modules/common/DisplaySelectModal/DisplaySelectModal';
import ErrorMsgPopUpForStock from '@components/modules/pc/stock_status/ErrorMsgPopUpForStock/ErrorMsgPopUpForStock';
import MemorizedListCell from '@components/modules/pc/production_schedule/MemorizedListCell/MemorizedListCell';
import ProductionScheduleEditable from '@components/modules/pc/production_schedule/ProductionScheduleEditable/ProductionScheduleEditable';
import ProductionScheduleMemorizedListCell from '@components/modules/pc/production_schedule/ProductionScheduleMemorizedListCell/ProductionScheduleMemorizedListCell';
import {
  generateMonthlyDateArray,
  isIncludes,
} from '@lib/pc/production_schedule/functions';
import { containsNonNumeric, replaceContent } from '@lib/common/functions';
import type { Todo, MonthlyData, FunctionType } from '@lib/common/type';
import type {
  MetaInfo,
  SelectedProduct,
} from '@lib/pc/production_schedule/type';
import { PRODUCT_KIND } from '@lib/common/type';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useUpdateProductionScheduleMutation } from '@lib/pc/production_schedule/hooks';

type Props = {
  selectedDate: Todo;
  selected: SelectedProduct | boolean | null;
  selectIndex: number;
  setSelectIndex: (index: number) => void;
  handleSelectedProduct: (
    d: Todo,
    productName: string,
    indexId: string
  ) => void;
  handleOpenProductDetail: (
    id: number,
    name: string,
    safetyStockDays: string
  ) => void;
  directMode: boolean;
  updateCount: number;
  setUpdateCount: (updateCount: number) => void;
  selectedFactoryId: number;
  selectedFactoryLineId: number;
  directUpdating: boolean;
  setDirectUpdating: (directUpdating: boolean) => void;
  updateTable: boolean;
  setUpdateTable: (updateTable: boolean) => void;
  clickToday: boolean;
  setClickToday: (clickToday: boolean) => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  selected,
  selectIndex,
  setSelectIndex,
  handleSelectedProduct,
  handleOpenProductDetail,
  directMode,
  updateCount,
  setUpdateCount,
  selectedFactoryId,
  selectedFactoryLineId,
  // directUpdating,
  setDirectUpdating,
  updateTable,
  setUpdateTable,
  clickToday,
  setClickToday,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [displaySelectModalActive, setDisplaySelectModalActive] =
    useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);
  const functionTypeObj = [
    { id: 0, type: '生産計画' },
    { id: 1, type: '生産実績' },
    { id: 2, type: '需要予測' },
    { id: 3, type: '出荷実績' },
    { id: 4, type: '理想在庫' },
    { id: 5, type: '予定在庫' },
    { id: 6, type: '実在庫' },
  ];
  const [selectedTypes, setSelectedTypes] =
    useState<FunctionType[]>(functionTypeObj);
  const selectedTypeIds = selectedTypes.map((item: FunctionType) => item['id']);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const observerTarget = useRef(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  // バックエンドからくるtotalPages
  const [totalPages, setTotalPages] = useState(0);
  // 初期ロード完了済みかどうか（完了済み：true）
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const [inputParams, setInputParams] = useState<Todo>({
    actualAmount: '',
    comment: '',
    checkEnabled: false,
  });

  // 更新処理のためのproductId
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectedSubmitDate, setSelectedSubmitDate] = useState<Date>();
  const [errMsg, setErrMsg] = useState('');

  // セルで直接変更した時→true
  const [isChanged, setIsChanged] = useState(false);

  // 直接入力モードと表示間で受け渡しする現在のセルの位置管理
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [currentCellIndex, setCurrentCellIndex] = useState(0);
  const [updateProductsPageId, setUpdateProductsPageId] = useState(0);

  const useDebounce = (value: string, delay: number) => {
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const [rightSlideSidebarsProductsPage, setRightSlideSidebarsProductsPage] =
    useState(0);

  // RightSlideSidebarで選択したproductがあるページ数の取得
  const getSelectedProductsPage = async (productId: number) => {
    // productが所属するpageを取得
    const pageId = itemToPageMap.get(productId);

    if (pageId || pageId === 0) {
      // pageIdを設定し、Promiseが解決するのを待つ
      await new Promise<void>((resolve) => {
        setRightSlideSidebarsProductsPage(pageId);
        // stateの更新が反映されるのを待つため、setTimeout を使用
        setTimeout(() => resolve(), 0);
      });

      setRightSlideSidebarsProductsPage(pageId);
    }
  };

  // ****************************
  const perPage = 5; // 1ページあたり取得件数
  // ****************************

  const [debouncedValue, setDebouncedValue] = useState('');

  // searchWordを200ミリ秒でデバウンス
  const debouncedSearchWord = useDebounce(searchWord, 200);

  type PageItems = {
    [pageId: number]: Todo[];
  };

  const [pageItems, setPageItems] = useState<PageItems>({});

  // 全てのitemsを一括で取得
  const allItems = useMemo(() => {
    return Object.values(pageItems).flat();
  }, [pageItems]);

  // ページ単位でitemsを更新
  const updatePageItems = (pageId: number, newItems: Todo[]) => {
    setPageItems((prevPageItems) => ({
      ...prevPageItems,
      [pageId]: newItems,
    }));
  };

  // 新しいitemsをページに追加
  const addItemsToPage = (pageId: number, newItems: Todo[]) => {
    setPageItems((prevPageItems) => ({
      ...prevPageItems,
      [pageId]: [...(prevPageItems[pageId] || []), ...newItems],
    }));
  };

  const itemToPageMap = new Map<number, number>();

  Object.entries(pageItems).forEach(([pageId, items]) => {
    const numericPageId = Number(pageId);
    // 現在のitems25件のみ
    items?.forEach((item) => {
      itemToPageMap.set(item.id, numericPageId);
    });
  });

  const fetchData = useCallback(
    async (currentPage: number, searchWord: string, afterUpdate?: boolean) => {
      if (loading || !hasMore) return;
      setLoading(true);
      try {
        const response = await axiosInstance().get(
          `/api/v1/production_schedules`,
          {
            params: {
              date: moment(selectedDate).format('YYYYMMDD'),
              factory_line_id: selectedFactoryLineId,
              factory_id: selectedFactoryId,
              page: currentPage + 1,
              search: searchWord,
              tag_ids: selectedTags
                ? selectedTags.map((item: Todo) => item['id'])
                : [],
              per_page: perPage,
            },
          }
        );
        const newItems = humps.camelizeKeys(
          response?.data?.basis_for_production_schedules
        );
        const meta = humps.camelizeKeys(
          response.data.meta
        ) as unknown as MetaInfo;

        setTotalPages(meta.totalPages);
        setHasMore(true);

        if (afterUpdate) {
          // ページ単位で更新
          updatePageItems(currentPage, newItems);
        } else {
          // 新しいitemsをページに追加
          addItemsToPage(currentPage, newItems);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
        setHasMore(true);
        setUpdateTable(false);
        setClickToday(false);
      }
    },
    [
      currentPage,
      searchWord,
      debouncedSearchWord,
      selectedTags,
      selectedFactoryId,
      selectedFactoryLineId,
      updateTable,
      selectedDate,
      clickToday,
    ]
  );

  useEffect(() => {
    setIsInitialLoadComplete(true);
  }, []);

  // RightSlideSidebarからの更新後、一覧画面更新
  useEffect(() => {
    if (updateTable) {
      // 更新したproductのページを指定してデータ取得
      fetchData(rightSlideSidebarsProductsPage, searchWord, true);
    }
  }, [updateTable]);

  // 今日ボタン押下時
  useEffect(() => {
    if (clickToday) {
      clearFetchedInfo();
      fetchData(0, searchWord);
    }
  }, [clickToday]);

  // 初回検索時設定に戻す
  const clearFetchedInfo = () => {
    setCurrentPage(0);
    setTotalPages(0);
    setPageItems({});
    setLoading(false);
    setHasMore(true);
  };

  // 検索エリアの商品名/商品コードクリア
  const handleClickSearchTextClear = () => {
    clearFetchedInfo();
    setSearchWord('');
    if (selectedTags.length > 0) {
      fetchData(currentPage, '');
    }
  };

  const handleUpdateSuccess = () => {
    setLoading(false);
    setHasMore(true);
    // 更新したproductのページを指定してデータ取得
    fetchData(updateProductsPageId, searchWord, true);

    setInputParams({
      ...inputParams,
      actualAmount: '',
      comment: '',
      checkEnabled: false,
    });
    setIsChanged(false);
    setDirectUpdating(false);
  };

  // 更新のmutation
  const updateProductionScheduleMutation = useUpdateProductionScheduleMutation(
    selectedProductId,
    selectedSubmitDate && selectedSubmitDate,
    handleUpdateSuccess,
    setErrMsg
  );

  // 直接入力セル更新処理
  const handleUpdate = async (inputParams: Todo) => {
    if (isChanged) {
      if (containsNonNumeric(inputParams.actualAmount)) {
        setErrMsg('半角数字のみ入力してください');
      } else {
        setDirectUpdating(true);
        try {
          // productが所属するpageを取得
          const pageId = itemToPageMap.get(selectedProductId);

          if (pageId || pageId === 0) {
            // pageIdを設定し、Promiseが解決するのを待つ
            await new Promise<void>((resolve) => {
              setUpdateProductsPageId(pageId);
              // stateの更新が反映されるのを待つため、setTimeout を使用
              setTimeout(() => resolve(), 0);
            });

            // pageIdが設定された後にmutateを呼び出す
            await updateProductionScheduleMutation.mutateAsync(inputParams);
          } else {
            console.error(
              'pageId not found for selectedProductId:',
              selectedProductId
            );
          }
        } catch (error) {
          console.error('Error during update:', error);
        } finally {
          setDirectUpdating(false);
        }
      }
    }
  };

  // 現在ページの設定
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // !loadingをページカウントアップ条件にいれる→fetchData時loading中だとreturnされるため
        if (
          entries[0].isIntersecting &&
          hasMore &&
          totalPages > currentPage &&
          !loading
        ) {
          // ページをカウントアップする
          setCurrentPage((p) => p + 1);
        }
      },
      {
        // threshold: 0.1,
        threshold: 1.0,
      }
    );

    // スクロール起動用divタグをIntersectionObserverに設定しています
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    // このページから他の画面に遷移した際にIntersectionObserverの設定を解除するようにしています
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [hasMore, observerTarget, totalPages, loading]);

  // 年月変更時の処理
  useEffect(() => {
    // 初期読み込み
    clearFetchedInfo();
    fetchData(0, searchWord);
  }, [selectedDate]);

  useEffect(() => {
    if (searchWord) {
      clearFetchedInfo();
      fetchData(currentPage, searchWord);
    }
  }, [searchWord]);

  useEffect(() => {
    if (debouncedSearchWord) {
      clearFetchedInfo();
      fetchData(currentPage, debouncedSearchWord);
    }
  }, [debouncedSearchWord]);

  // 選択中のタグを全て外して表示対象が0件になった時
  useEffect(() => {
    if (
      selectedTags.length === 0 &&
      allItems.length === 0 &&
      isInitialLoadComplete
    ) {
      clearFetchedInfo();
      fetchData(currentPage, searchWord);
    }
  }, [selectedTags.length]);

  // タグ選択０件時、再度fetchする必要があるが初期表示時もfetchしてしまうため条件追加が必要
  useEffect(() => {
    if (currentPage !== 0) {
      fetchData(currentPage, searchWord);
    } else if (selectedTags.length > 0) {
      fetchData(currentPage, searchWord);
    }
  }, [currentPage, selectedTags.length]);

  // 工場、ライン変更時
  useEffect(() => {
    if (selectedFactoryId || selectedFactoryLineId) {
      clearFetchedInfo();
      setDebouncedValue('');
      setPageItems({});
      fetchData(0, searchWord);
    }
  }, [selectedFactoryId, selectedFactoryLineId]);

  return (
    <>
      <div id="whole-wrapper" className="whole-wrapper">
        <S.ListWrapper className="list-wrapper">
          <S.ListSidebar className="list-sidebar">
            <S.SearchArea>
              <S.ListLeftHeadCell
                onClick={() => setTagMenuActive(!tagMenuActive)}
                isBlue={searchWord !== '' || selectedTagIds.length > 0}
              >
                <span className="filter-product">
                  {searchWord !== '' || selectedTagIds.length > 0 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">商品絞込み中</div>
                    </>
                  ) : (
                    <>
                      <Filter />
                      <div className="filter_text">商品の絞込</div>
                    </>
                  )}
                </span>
              </S.ListLeftHeadCell>
              <S.DisplayButton
                onClick={() =>
                  setDisplaySelectModalActive(!displaySelectModalActive)
                }
                isBlue={selectedTypeIds.length !== 7}
              >
                <span className="filter-product">
                  {selectedTypeIds.length !== 7 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">選択中</div>
                    </>
                  ) : (
                    <>
                      <Filter />
                      <div className="filter_text">表示選択</div>
                    </>
                  )}
                </span>
              </S.DisplayButton>
            </S.SearchArea>
            {allItems.map(
              (
                s: {
                  id: number;
                  name: string;
                  nameAbbreviation: string;
                  nameKana: string;
                  productCode: number;
                  safetyStockDays: string;
                },
                i: number
              ) => {
                return (
                  // 商品名、商品コード
                  <S.ListLeftHeadProductCell
                    className="list-left-product-cell"
                    key={`${i}-${s.id}`}
                    productIndex={i}
                  >
                    <S.ListTbody key={`list-body${i}-${s.id}-${s.productCode}`}>
                      <S.ListLeftHeadNameAndCode
                        key={`${i}-${s.id}-${s.productCode}`}
                        productIndex={i}
                        onClick={() =>
                          handleOpenProductDetail(
                            s.id,
                            s.nameAbbreviation ? s.nameAbbreviation : s.name,
                            !s.safetyStockDays ? '3' : String(s.safetyStockDays)
                          )
                        }
                      >
                        {/* 商品名 */}
                        <S.ListProductNameAndCode
                          key={`product-code${i}-${s.id}-${s.productCode}`}
                        >
                          <S.ListProductName
                            key={`product-name${i}-${s.id}-${s.name}`}
                          >
                            {replaceContent(
                              s.nameAbbreviation ? s.nameAbbreviation : s.name,
                              12.5
                            )}
                          </S.ListProductName>
                          {/* 商品コード */}
                          {s.productCode}
                        </S.ListProductNameAndCode>
                      </S.ListLeftHeadNameAndCode>
                      <div
                        className="type-name"
                        key={`type-name${i}-${s.id}-${s.productCode}`}
                      >
                        {functionTypeObj
                          .filter((value) => selectedTypeIds.includes(value.id))
                          .map((value, j) => {
                            // 表示タイプ
                            return (
                              <S.ListLeftHeadTypeName
                                key={`${i}-${j}-${value.id}`}
                                typeIndex={j}
                                productIndex={i}
                                notDisplay={
                                  !selectedTypeIds.includes(value.id)
                                    ? 'true'
                                    : ''
                                }
                              >
                                {value.type}
                              </S.ListLeftHeadTypeName>
                            );
                          })}
                      </div>
                    </S.ListTbody>
                  </S.ListLeftHeadProductCell>
                );
              }
            )}
          </S.ListSidebar>
          <S.ListBody
            className={selected && !directMode ? 'move-to-left' : ''}
            id="scrollArea"
          >
            <S.ListRow className="list-row">
              {allItems?.length > 0 &&
                generateMonthlyDateArray(
                  allItems[0]?.numberOfDaysParamsMonth,
                  selectedDate,
                  selectIndex
                ).map((p, i) => {
                  return (
                    // 月日、合計、前年同月
                    <S.ListHeadCell
                      key={`${i}-${p.productionDate}`}
                      index={i}
                      numOfIndex={allItems[0].numberOfDaysParamsMonth}
                      highLighten={p.isToday}
                      dayOfWeek={moment(p.productionDate).format('ddd')}
                      onClick={() => {
                        setSelectIndex(i);
                      }}
                    >
                      {i === 0
                        ? moment(p.productionDate).format('M/D(ddd)')
                        : i === allItems[0].numberOfDaysParamsMonth + 1
                        ? '合計'
                        : i === allItems[0].numberOfDaysParamsMonth + 2
                        ? '前年同月'
                        : moment(p.productionDate).format('DD(ddd)')}
                    </S.ListHeadCell>
                  );
                })}
            </S.ListRow>
            {/*各日付の下 */}
            {allItems?.length > 0 ? (
              allItems.map((p: Todo, i: number) => {
                return (
                  <React.Fragment key={`${i}-${p.productCode}`}>
                    {directMode && !tagMenuActive ? (
                      <S.ListRow key={`${i}-${p.name}`}>
                        {p.productionSchedules.map(
                          (schedule: MonthlyData[], idx: number) => {
                            return (
                              <ProductionScheduleEditable
                                p={p}
                                key={`production-schedule-editable-${p.productCode}-${p.name}-${i}-${idx}`}
                                currentRowIndex={currentRowIndex}
                                setCurrentRowIndex={setCurrentRowIndex}
                                currentCellIndex={currentCellIndex}
                                setCurrentCellIndex={setCurrentCellIndex}
                                selectIndex={selectIndex}
                                setSelectedProductId={setSelectedProductId}
                                setSelectedSubmitDate={setSelectedSubmitDate}
                                inputParams={schedule}
                                isChanged={isChanged}
                                setIsChanged={setIsChanged}
                                handleUpdate={handleUpdate}
                                setErrMsg={setErrMsg}
                                updateCount={updateCount}
                                setUpdateCount={setUpdateCount}
                                rowIndex={i}
                                cellIndex={idx}
                                productLength={allItems.length}
                                totalTitle={
                                  idx ===
                                  allItems[0].numberOfDaysParamsMonth + 1
                                    ? '合計'
                                    : idx ===
                                      allItems[0].numberOfDaysParamsMonth + 2
                                    ? '前年同月'
                                    : ''
                                }
                              />
                            );
                          }
                        )}
                      </S.ListRow>
                    ) : (
                      <S.ListRow key={`${i}-${p.id}`}>
                        {p.productionSchedules.map(
                          (schedule: MonthlyData[], idx: number) => {
                            // 生産計画
                            return (
                              <ProductionScheduleMemorizedListCell
                                key={`${i}-${p.id}-${idx}`}
                                selected={selected}
                                rowIndex={i}
                                p={p}
                                paramsType={schedule}
                                cellIndex={idx}
                                selectIndex={selectIndex}
                                monthlyDateNum={p.numberOfDaysParamsMonth}
                                handleSelectedProduct={handleSelectedProduct}
                                currentRowIndex={currentRowIndex}
                                setCurrentRowIndex={setCurrentRowIndex}
                                currentCellIndex={currentCellIndex}
                                setCurrentCellIndex={setCurrentCellIndex}
                                getSelectedProductsPage={
                                  getSelectedProductsPage
                                }
                              />
                            );
                          }
                        )}
                      </S.ListRow>
                    )}

                    <S.ListRow
                      notDisplay={!selectedTypeIds.includes(1) ? 'true' : ''}
                    >
                      {selectedTypeIds.includes(1) &&
                        p.productProductionResults.map(
                          (
                            productProductionResult: MonthlyData[],
                            idx: number
                          ) => {
                            return (
                              <MemorizedListCell
                                key={`${i}-${p.id}-${idx}`}
                                index={i}
                                p={p}
                                paramsType={productProductionResult}
                                idx={idx}
                                selectIndex={selectIndex}
                                last={selectedTypeIds.length === 2}
                                monthlyDateNum={p.numberOfDaysParamsMonth}
                              />
                            );
                          }
                        )}
                    </S.ListRow>
                    <S.ListRow
                      notDisplay={!selectedTypeIds.includes(2) ? 'true' : ''}
                    >
                      {selectedTypeIds.includes(2) &&
                        p.demandForecasts.map(
                          (demandForecast: MonthlyData[], idx: number) => {
                            return (
                              <MemorizedListCell
                                key={`${i}-${p.id}-${idx}`}
                                index={i}
                                p={p}
                                paramsType={demandForecast}
                                idx={idx}
                                selectIndex={selectIndex}
                                last={
                                  selectedTypeIds.length === 2 ||
                                  (selectedTypeIds.includes(1) &&
                                    selectedTypeIds.length == 3)
                                }
                                monthlyDateNum={p.numberOfDaysParamsMonth}
                              />
                            );
                          }
                        )}
                    </S.ListRow>
                    <S.ListRow
                      notDisplay={!selectedTypeIds.includes(3) ? 'true' : ''}
                    >
                      {selectedTypeIds.includes(3) &&
                        p.shippings.map(
                          (shipping: MonthlyData[], idx: number) => {
                            return (
                              <MemorizedListCell
                                key={`${i}-${p.id}-${idx}`}
                                index={i}
                                p={p}
                                paramsType={shipping}
                                idx={idx}
                                selectIndex={selectIndex}
                                last={
                                  selectedTypeIds.length === 2 ||
                                  (selectedTypeIds.every(
                                    (v: number) => v <= 3
                                  ) &&
                                    !selectedTypeIds.some(
                                      (v: number) => v >= 4
                                    ) &&
                                    selectedTypeIds.length == 3) ||
                                  !isIncludes([4, 5, 6], selectedTypeIds)
                                }
                                monthlyDateNum={p.numberOfDaysParamsMonth}
                              />
                            );
                          }
                        )}
                    </S.ListRow>
                    <S.ListRow
                      notDisplay={!selectedTypeIds.includes(4) ? 'true' : ''}
                    >
                      {selectedTypeIds.includes(4) &&
                        p.safetyStocks.map(
                          (safetyStock: MonthlyData[], idx: number) => {
                            return (
                              <MemorizedListCell
                                key={`${i}-${p.id}-${idx}`}
                                index={i}
                                p={p}
                                paramsType={safetyStock}
                                idx={idx}
                                selectIndex={selectIndex}
                                last={
                                  selectedTypeIds.length === 2 ||
                                  !isIncludes([5, 6], selectedTypeIds)
                                }
                                monthlyDateNum={p.numberOfDaysParamsMonth}
                              />
                            );
                          }
                        )}
                    </S.ListRow>
                    <S.ListRow
                      notDisplay={!selectedTypeIds.includes(5) ? 'true' : ''}
                    >
                      {selectedTypeIds.includes(5) &&
                        p.estimatedProductStocks.map(
                          (
                            estimatedProductStock: MonthlyData[],
                            idx: number
                          ) => {
                            return (
                              <MemorizedListCell
                                key={`${i}-${p.id}-${idx}`}
                                index={i}
                                p={p}
                                paramsType={estimatedProductStock}
                                idx={idx}
                                selectIndex={selectIndex}
                                last={
                                  selectedTypeIds.length === 2 ||
                                  !selectedTypeIds.includes(6)
                                }
                                monthlyDateNum={p.numberOfDaysParamsMonth}
                              />
                            );
                          }
                        )}
                    </S.ListRow>
                    <S.ListRow
                      notDisplay={!selectedTypeIds.includes(6) ? 'true' : ''}
                    >
                      {selectedTypeIds.includes(6) &&
                        p.actualProductStocks.map(
                          (actualProductStock: MonthlyData[], idx: number) => {
                            return (
                              <MemorizedListCell
                                key={`${i}-${p.id}-${idx}`}
                                index={i}
                                p={p}
                                paramsType={actualProductStock}
                                idx={idx}
                                selectIndex={selectIndex}
                                last={true}
                                monthlyDateNum={p.numberOfDaysParamsMonth}
                              />
                            );
                          }
                        )}
                    </S.ListRow>
                  </React.Fragment>
                );
              })
            ) : (
              <>
                {hasMore && loading && !searchWord && (
                  <S.CircularIconWrapper>
                    <CircularProgress style={{ color: '#64b2f9' }} />
                  </S.CircularIconWrapper>
                )}
              </>
            )}
            <S.InfiniteScrollWrapper>
              <S.ObserverTarget
                className="observerTarget"
                ref={observerTarget}
                tagMenuActive={tagMenuActive}
              >
                {hasMore && loading && (
                  <S.CircularIconWrapper infiniteScroll>
                    <CircularProgress style={{ color: '#64b2f9' }} />
                  </S.CircularIconWrapper>
                )}
              </S.ObserverTarget>
            </S.InfiniteScrollWrapper>
          </S.ListBody>
        </S.ListWrapper>
      </div>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => {
            handleClickSearchTextClear();
          }}
          clearFetchedInfo={clearFetchedInfo}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => {
            setSelectedTags(tags);
            clearFetchedInfo();
          }}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={PRODUCT_KIND.PRODUCT}
        />
      )}
      {displaySelectModalActive && (
        <DisplaySelectModal
          notSelectableObj={'生産計画'}
          selectedTypes={selectedTypes}
          functionTypeObj={functionTypeObj}
          setSelectedTypes={(type) => setSelectedTypes(type)}
          displaySelectModalActive={displaySelectModalActive}
          handleClose={() => setDisplaySelectModalActive(false)}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
      <ErrorMsgPopUpForStock
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
        width={'20rem'}
      />
    </>
  );
};

export default TableWithFixedSidebar;
